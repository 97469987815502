var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"my-0",attrs:{"flat":""}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":true}}):_c('v-layout',{attrs:{"column":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":"","align-center":""}},[_c('span',{},[_c('div',{staticClass:"display-1 mb-4"},[_c('v-icon',{attrs:{"size":"40","color":"primary"}},[_vm._v("dashboard")]),_c('span',{staticClass:"ml-2"},[_vm._v("Re-scrutiny Result Summary")])],1)]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":_vm.exportPdf}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("arrow_downward")]),_vm._v("Download Re-scrutiny Summary ")],1)],1),(_vm.show)?_c('v-layout',{attrs:{"wrap":""}},[_vm._l((_vm.getProcessedData),function(item,i){return _c('v-flex',{key:i,attrs:{"sm6":"","md4":"","lg4":"","xs8":""}},[_c('v-layout',{staticStyle:{"margin":"15px"},attrs:{"align-center":"","justify-center":"","row":"","wrap":"","fill-height":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return _c('v-card',{class:` elevation-${hover ? 12 : 2}`,staticStyle:{"border-radius":"10px"},attrs:{"height":"90","width":"320"},on:{"click":function($event){return _vm.goTo(item.link)}}},[_c('v-layout',{attrs:{"column":"","wrap":"","fill-height":"","align-start":"","justify-start":"","align-content-start":""}},[_c('v-card',{staticStyle:{"border-radius":"10px 0 0 10px"},attrs:{"color":item.color,"height":"90","width":"70"}},[_c('v-layout',{attrs:{"align-center":"","justify-center":"","row":"","fill-height":""}},[_c('v-icon',{attrs:{"color":"white","size":"50"}},[_vm._v(_vm._s(item.icon))])],1)],1),_c('v-card',{attrs:{"flat":"","max-width":"220","width":"220","max-height":"90","height":"90"}},[(
                        item.title[0] != 'passed_percentage' &&
                          item.title[0] != 'failed_percentage' && item.title[0] != 'others_percentage'
                      )?_c('div',{staticClass:"ml-3",staticStyle:{"font-size":"2.2em"}},[_c('b',[_c('i-count-up',{attrs:{"delay":_vm.delay,"start":0,"endVal":parseInt(item.title[1]),"options":_vm.options,"callback":_vm.callback}})],1)]):_vm._e(),(
                        item.title[0] == 'passed_percentage' ||
                          item.title[0] == 'failed_percentage' || item.title[0] == 'others_percentage'
                      )?_c('div',{staticClass:"ml-3",staticStyle:{"font-size":"2.2em"}},[_c('b',[_c('i-count-up',{attrs:{"delay":_vm.delay,"start":0,"endVal":parseFloat(item.title[1]),"options":_vm.optionsl,"callback":_vm.callback}})],1),_c('b',[_vm._v("%")])]):_vm._e(),_c('h4',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(item.title[0] .toUpperCase() .split('_') .join(' '))+" ")])])],1)],1)}}],null,true)})],1)],1)}),_c('v-card',{attrs:{"flat":""}})],2):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }